import * as React from "react"
import Seo from "../components/seo"

const IndexPage = () => (
  <main
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <Seo title="Home" />
    <h1
      style={{
        fontSize: "5rem",
        fontWeight: "bold",
        margin: 0,
      }}
    >
      SEOMUSED
    </h1>
  </main>
)

export default IndexPage
